.Version form {
  padding-bottom: 15px;
}

.Version .LoaderButton {
  margin-top: 10px;
}


.Pumps {
  padding-top: 40px;
}