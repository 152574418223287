.NewDiscount form {
  padding-bottom: 15px;
}

.NewDiscount .LoaderButton {
  margin-top: 25px;
}


.NewDiscount {
  padding-top: 40px;
}
