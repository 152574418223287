.NewPump form {
  padding-bottom: 15px;
}

.NewPump {
  padding-top: 40px;
}

.NewPump .LoaderButton {
  margin-top: 25px;
}
