.NewTrainer form {
  padding-bottom: 15px;
}

.NewTrainer .LoaderButton {
  margin-top: 25px;
}


.NewTrainer {
  padding-top: 40px;
}
