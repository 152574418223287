.NewUser form {
  padding-bottom: 15px;
}

.NewUser .LoaderButton {
  margin-top: 25px;
}


.NewUser {
  padding-top: 40px;
}