.User form {
  padding-bottom: 15px;
}

.User .LoaderButton {
  margin-top: 10px;
}


.User {
  padding-top: 40px;
}

.rag-red {
  background-color: lightcoral;
}
.rag-green {
  background-color: lightgreen;
}
.rag-amber {
  background-color: lightsalmon;
}