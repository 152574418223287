.NewClinic form {
  padding-bottom: 15px;
}

.NewClinic .LoaderButton {
  margin-top: 25px;
}

.NewClinic {
  padding-top: 40px;
}