.Notice .lander {
    padding: 80px 0;
    text-align: center;
}

.Notice .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Notice .lander p {
    color: #999;
}

.Notice .lander div {
    padding-top: 20px;
}

/*.Notice .lander div a:first-child {*/
/*  margin-right: 20px;*/
/*}*/

.Notice .users h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Notice .users h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Notice .users p {
    color: #666;
}

.Notice Button {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
}

.Notice .button-duplicate {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
    margin-left: 20px;
}

.Notice .button-info {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
    margin-left: 20px;
}

.Notice .page-size-header {
    font-weight: 300;
    font-size: smaller;
    margin-right: 15px;
}

.Notice input {
    font-weight: 300;
    font-size: smaller;
}

.Notice .page-container {
    margin-bottom: 8px;
}

.Notice .pump-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.btn-del-customer {
    max-width: 150px;
}

.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
}

.rag-red {
     background-color: lightcoral;
 }
.rag-green {
    background-color: lightgreen;
}
.rag-amber {
    background-color: lightsalmon;
}