.SentEmails .lander {
    padding: 80px 0;
    text-align: center;
}

.SentEmails .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.SentEmails .lander p {
    color: #999;
}

.SentEmails .lander div {
    padding-top: 20px;
}

/*.SentEmails .lander div a:first-child {*/
/*  margin-right: 20px;*/
/*}*/

.SentEmails .users h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.SentEmails .users h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.SentEmails .users p {
    color: #666;
}

.SentEmails Button {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
}

.SentEmails .button-duplicate {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
    margin-left: 20px;
}

.SentEmails .button-info {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
    margin-left: 20px;
}

.SentEmails .page-size-header {
    font-weight: 300;
    font-size: smaller;
    margin-right: 15px;
}

.SentEmails input {
    font-weight: 300;
    font-size: smaller;
}

.SentEmails .page-container {
    margin-bottom: 8px;
}

.SentEmails .pump-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
}
