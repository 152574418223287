.NewVersion form {
  padding-bottom: 15px;
}

.NewVersion .LoaderButton {
  margin-top: 25px;
}

.NewVersion {
  padding-top: 40px;
}