.Discount form {
  padding-bottom: 15px;
}

.Discount .LoaderButton {
  margin-top: 10px;
}


.Pumps {
  padding-top: 40px;
}